<template>
    <div
        class="notification"
        :class="{ error: type === 'error' }"
        :style="`--duration: ${duration}ms; }`"
    >
        <div class="head">
            <span class="title">{{ title }}</span>
            <button
                class="close-btn"
                @click="$store.commit('removeNotification', id)"
            >
                <i class="fas fa-times"></i>
            </button>
        </div>
        <span class="content">{{ content }}</span>
    </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
            default: 'Message',
        },
        content: {
            type: String,
            required: true,
            default: 'Example',
        },
        duration: {
            type: Number,
            default: 5000,
        },
        createdAt: {
            type: Number,
            default: +new Date(),
        },
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'default',
        },
    },
    setup(props) {
        const store = useStore()
        onMounted(() => {
            setTimeout(() => {
                store.commit('removeNotification', props.id)
            }, props.duration)
        })

        return {}
    },
})
</script>

<style lang="scss" scoped>
.notification {
    background-color: rgb(30, 32, 38);
    position: relative;
    margin: 10px;
    min-height: 50px;
    min-width: 200px;
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.84);
    color: rgb(255, 255, 255);
    text-align: left;
    pointer-events: all;
    font-family: Inter, sans-serif;
    --duration: 5000ms;

    &.error {
        background-color: rgb(248, 73, 96);
    }

    .head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: 20px;

        .close-btn {
            background: none;
            border: none;
            outline: none;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            //background: rgb(30, 33, 38);
            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
        margin: 0 0 5px 0;
    }

    .content {
        font-size: 17px;
        color: #f1f1f1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &::before {
        content: '';
        height: 3px;
        width: 0%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgb(240, 185, 11);
        animation: ttl var(--duration) linear 100ms;
    }
}

@keyframes ttl {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}
</style>
