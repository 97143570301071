
import 'animate.css'
import '@/styles/tooltip.scss'
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        return {}
    },
})
