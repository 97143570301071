
import { useStore } from 'vuex'
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
            default: 'Message',
        },
        content: {
            type: String,
            required: true,
            default: 'Example',
        },
        duration: {
            type: Number,
            default: 5000,
        },
        createdAt: {
            type: Number,
            default: +new Date(),
        },
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'default',
        },
    },
    setup(props) {
        const store = useStore()
        onMounted(() => {
            setTimeout(() => {
                store.commit('removeNotification', props.id)
            }, props.duration)
        })

        return {}
    },
})
