<template>
    <div class="loading-overlay">
        <GridLoader class="loader" color="rgb(240, 185, 11)" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default defineComponent({
    components: {
        GridLoader,
    },
    setup() {
        return {}
    },
})
</script>

<style lang="scss" scoped>
.loading-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 250ms ease;
    z-index: 3;
    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
