export const positionOptions = { enableHighAccuracy: true }

export const hashtagLocation = window.location.hash.substr(1)

export const polygonPaint = {
    'line-color': 'black',
}

export const darkmodePolygonPaint = {
    'line-color': 'white',
}

export const tooClosePaint = {
    //"fill-color": "#FF4136",
    //"fill-color": "#FC4445",
    'fill-color': '#F66F67',
    'fill-opacity': 0.8,
    'fill-outline-color': '#8b0000',
    //'background': 'red'
}

export const safeRingPaint = {
    'fill-color': '#A8C686',
    //"fill-outline-color": "white",
    'fill-opacity': [
        'interpolate',
        ['linear'],
        ['get', 'ring'],
        8,
        0.9,
        10,
        0.8,
        12,
        0.7,
        14,
        0.6,
        16,
        0.5,
        18,
        0.3,
        20,
        0.1,
    ],
    //'background': 'green'
}

export const locationPaint = {
    'fill-color': 'grey',
    'fill-opacity': 1,
    //'background': 'blue'
}

export const nearbyPaint = {
    //"fill-color": "#0074D9",
    'fill-color': [
        'case',
        ['==', ['get', 'rewardScale'], null],
        'black',
        ['==', ['get', 'status'], 'offline'],
        'black',
        [
            'interpolate',
            ['linear'],
            ['get', 'rewardScale'],
            0.1,
            '#D4070F',
            0.3,
            '#F18009',
            0.5,
            '#FFDA13',
            0.8,
            '#00FFFF',
            0.9,
            '#0066E7',
            1,
            '#1C1AAF',
        ],
    ],
    'fill-opacity': 1,
    'fill-outline-color': 'white',
    //'background': 'purple'
}
