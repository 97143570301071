import { createI18n } from 'vue-i18n'
import de from '@/lang/de'

const i18n = createI18n({
    locale: 'de',
    fallbackLocale: 'en',
    messages: {
        de,
    },
})

export default i18n
