
import { defineComponent } from 'vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default defineComponent({
    components: {
        GridLoader,
    },
    setup() {
        return {}
    },
})
