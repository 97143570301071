<template>
    <div class="home">
        <div class="menu" :class="{ expanded: expandMenu }">
            <div class="map-styles m-sec style-grid">
                <input
                    v-model="style"
                    id="satellite-v9"
                    type="radio"
                    name="rtoggle"
                    value="satellite"
                    checked="checked"
                    class="menu-radio"
                />
                <!-- See a list of Mapbox-hosted public styles at -->
                <!-- https://docs.mapbox.com/api/maps/styles/#mapbox-styles -->
                <!--<label for="satellite-v9">{{ $t("satellite") }}</label>-->
                <label
                    class="menu-radio-label"
                    for="satellite-v9"
                    v-tooltip:right.tooltip="$t('satellite')"
                    :disable-tooltip="expandMenu"
                    ><i class="fas fa-satellite"></i
                ></label>
                <input
                    id="light-v10"
                    type="radio"
                    name="rtoggle"
                    value="light"
                    v-model="style"
                    class="menu-radio"
                />
                <!--<label for="light-v10">{{ $t("light") }}</label>-->
                <label
                    class="menu-radio-label"
                    for="light-v10"
                    v-tooltip:right.tooltip="$t('light')"
                    :disable-tooltip="expandMenu"
                    ><i class="fas fa-sun"></i
                ></label>
                <input
                    v-model="style"
                    id="dark-v10"
                    type="radio"
                    name="rtoggle"
                    value="dark"
                    class="menu-radio"
                />
                <!--<label for="dark-v10">{{ $t("dark") }}</label>-->
                <label
                    class="menu-radio-label"
                    for="dark-v10"
                    v-tooltip:right.tooltip="$t('dark')"
                    :disable-tooltip="expandMenu"
                    ><i class="fas fa-moon"></i
                ></label>
                <input
                    v-model="style"
                    id="streets-v11"
                    type="radio"
                    name="rtoggle"
                    value="streets"
                    class="menu-radio"
                />
                <!--<label for="streets-v11">{{ $t("streets") }}</label>-->
                <label
                    class="menu-radio-label"
                    for="streets-v11"
                    v-tooltip:right.tooltip="$t('streets')"
                    :disable-tooltip="`${expandMenu}`"
                >
                    <i class="fas fa-road"></i
                ></label>
                <input
                    v-model="style"
                    id="outdoors-v11"
                    type="radio"
                    name="rtoggle"
                    value="outdoors"
                    class="menu-radio"
                />
                <!--<label for="outdoors-v11">{{ $t("outdoors") }}</label>-->
                <label
                    class="menu-radio-label"
                    for="outdoors-v11"
                    v-tooltip:right.tooltip="$t('outdoors')"
                    :disable-tooltip="expandMenu"
                    ><i class="fas fa-mountain"></i
                ></label>
            </div>
            <div class="resolutions m-sec res-grid">
                <div
                    class="res-checkbox"
                    v-for="(res, idx) in Object.keys(resolutions)"
                    :key="idx"
                    @click="resolutions[res] = !resolutions[res]"
                    :class="{ active: resolutions[res] }"
                    v-tooltip:right.tooltip="$t('resolution', { res })"
                    :disable-tooltip="expandMenu"
                >
                    <!--<input class="res-input" type="checkbox" v-model="resolutions[res]" />-->
                    <label class="res-label" :data-res="res"> &#11041; </label>
                </div>
            </div>
            <div class="info m-sec info-grid">
                <button
                    class="menu-radio-label"
                    v-tooltip:right.tooltip="$t('togglegoodrange')"
                    @click="goodRange = !goodRange"
                    :class="{ active: goodRange }"
                    :disable-tooltip="expandMenu"
                >
                    <i class="fas fa-check-circle"></i>
                </button>
                <button
                    class="menu-radio-label"
                    v-tooltip:right.tooltip="$t('togglebadrange')"
                    @click="badRange = !badRange"
                    :class="{ active: badRange }"
                    :disable-tooltip="expandMenu"
                >
                    <i class="fas fa-exclamation-circle"></i>
                </button>
                <a
                    v-tooltip:right.tooltip="$t('readhip17')"
                    :disable-tooltip="expandMenu"
                    href="https://engineering.helium.com/2020/12/09/blockchain-release-hip-17.html"
                    ><i class="fas fa-info-circle"></i
                ></a>
            </div>
            <div class="options m-sec option-grid">
                <button class="menu-btn" @click="locate">
                    <i class="fas fa-location-arrow"></i>
                </button>
                <button class="menu-btn" @click="showOptions = !showOptions">
                    <i class="fas fa-cog"></i>
                </button>
                <button class="menu-btn" @click="expandMenu = !expandMenu">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
            <div class="label-cont style-grid" v-if="expandMenu">
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.1s!important`"
                >
                    {{ $t('satellite') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.15s!important`"
                >
                    {{ $t('light') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.2s!important`"
                >
                    {{ $t('dark') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.25s!important`"
                >
                    {{ $t('streets') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.3s!important`"
                >
                    {{ $t('outdoors') }}
                </label>
            </div>
            <div class="label-cont move-top res-grid" v-if="expandMenu">
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    v-for="(res, idx) in Object.keys(resolutions)"
                    :key="idx"
                    :style="`--animate-delay: ${
                        0.3 + 0.05 + idx * 0.05
                    }s!important`"
                >
                    {{ $t('resolution', { res }) }}
                </label>
            </div>
            <div class="label-cont" v-if="expandMenu"></div>
            <div class="label-cont info-grid" v-if="expandMenu">
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.65s!important`"
                >
                    {{ $t('togglegoodrange') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.7s!important`"
                >
                    {{ $t('togglebadrange') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.75s!important`"
                >
                    {{ $t('readhip17') }}
                </label>
            </div>
            <div class="label-cont option-grid" v-if="expandMenu">
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.8s!important`"
                >
                    {{ $t('locate') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.85s!important`"
                >
                    {{ $t('settings') }}
                </label>
                <label
                    class="animate__animated animate__fadeInRight animate__delay-2s"
                    :style="`--animate-delay: 0.9s!important`"
                >
                    {{ $t('close') }}
                </label>
            </div>
        </div>
        <div
            class="notifications"
            :style="`left: ${expandMenu ? '200px' : '70px'}`"
        >
            <transition-group name="notify">
                <Notification
                    v-for="(notify, idx) in notifications"
                    :key="idx"
                    :title="notify.title"
                    :content="notify.content"
                    :duration="notify.duration"
                    :id="notify.id"
                    :type="notify.type"
                />
            </transition-group>
        </div>
        <div id="map" class="map"></div>
        <transition name="fade">
            <loading-overlay v-show="loading" />
        </transition>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import mapboxgl from 'mapbox-gl'
import * as h3 from 'h3-js'

import Notification from '@/components/Notification.vue'

import {
    clearResolution,
    getResolutionGeoJson,
    renderResolution,
} from '@/functions/hexagons'
import {
    locationPaint,
    safeRingPaint,
    tooClosePaint,
} from '@/functions/hexPaintings'
import LoadingOverlay from '@/components/LoadingOverlay.vue'

export default defineComponent({
    name: 'Home',
    components: { Notification, LoadingOverlay },
    setup() {
        const config = {
            lat: 52.2799,
            lng: 8.0472,
            zoom: 12,
        }

        const store = useStore()
        const i18n = useI18n()

        const notifications = computed(() => store.getters.notifications)

        const style = ref('streets')
        const resolutions = ref({
            //4: false,
            //5: false,
            6: false,
            7: true,
            8: true,
            9: false,
            10: false,
            //11: false,
            //12: false,
        })

        const goodRange = ref(true)
        const badRange = ref(true)
        const expandMenu = ref(false)
        const showOptions = ref(false)

        const loading = ref(true)
        /**
         * [lat, lng]
         */
        const selectedPoint = ref([0, 0])

        let map: mapboxgl.Map

        function getMapStyle(type: string): string {
            const baseUrl = 'mapbox://styles/mapbox/'

            switch (type) {
                case 'satellite':
                    return baseUrl + 'satellite-v9'
                case 'light':
                    return baseUrl + 'light-v10'
                case 'dark':
                    return baseUrl + 'dark-v10'
                case 'streets':
                    return baseUrl + 'streets-v11'
                case 'outdoors':
                    return baseUrl + 'outdoors-v11'
                default:
                    return baseUrl + 'streets-v11'
            }
        }

        function locate() {
            console.log('locate me')
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        selectPoint(pos.coords.longitude, pos.coords.latitude)

                        store.commit('addNotification', {
                            title: i18n.t('notifications.locate_succ.title'),
                            content: i18n.t(
                                'notifications.locate_succ.message'
                            ),
                            duration: 5000,
                        })
                    },
                    (err) => {
                        console.error(err)
                        store.commit('addNotification', {
                            title: i18n.t('notifications.locate_perm.title'),
                            content: i18n.t(
                                'notifications.locate_perm.message'
                            ),
                            duration: 5000,
                            type: 'error',
                        })
                    }
                )
            } else {
                store.commit('addNotification', {
                    title: i18n.t('notifications.locate_err.title'),
                    content: i18n.t('notifications.locate_err.message'),
                    duration: 5000,
                    type: 'error',
                })
            }
        }

        function selectPoint(lng: number, lat: number) {
            selectedPoint.value = [lat, lng]

            let newlatitude = 0
            let newlongitude = 0

            let res12hex = null
            let res11hex = null
            try {
                newlatitude = lat
                newlongitude = lng
                res12hex = h3.geoToH3(lat, lng, 12)
                res11hex = h3.geoToH3(lat, lng, 11)
            } catch {
                console.log('handleOnResult: Geolocate')
                newlatitude = lat
                newlongitude = lng
                res12hex = h3.geoToH3(lat, lng, 12)
                res11hex = h3.geoToH3(lat, lng, 11)
            }

            const updatedlocation = {
                latitude: newlatitude,
                longitude: newlongitude,
                res12hex: res12hex,
                res11hex: res11hex,
            }

            const res11safehexes = []
            res11safehexes.push({
                ring: 1,
                hexes: h3.hexRing(updatedlocation.res11hex, 8),
            })
            res11safehexes.push({
                ring: 2,
                hexes: h3.hexRing(updatedlocation.res11hex, 9),
            })
            res11safehexes.push({
                ring: 3,
                hexes: h3.hexRing(updatedlocation.res11hex, 10),
            })
            res11safehexes.push({
                ring: 4,
                hexes: h3.hexRing(updatedlocation.res11hex, 11),
            })
            res11safehexes.push({
                ring: 5,
                hexes: h3.hexRing(updatedlocation.res11hex, 12),
            })
            res11safehexes.push({
                ring: 6,
                hexes: h3.hexRing(updatedlocation.res11hex, 13),
            })
            res11safehexes.push({
                ring: 7,
                hexes: h3.hexRing(updatedlocation.res11hex, 14),
            })
            res11safehexes.push({
                ring: 8,
                hexes: h3.hexRing(updatedlocation.res11hex, 15),
            })
            res11safehexes.push({
                ring: 9,
                hexes: h3.hexRing(updatedlocation.res11hex, 16),
            })
            res11safehexes.push({
                ring: 10,
                hexes: h3.hexRing(updatedlocation.res11hex, 17),
            })
            res11safehexes.push({
                ring: 11,
                hexes: h3.hexRing(updatedlocation.res11hex, 18),
            })
            res11safehexes.push({
                ring: 12,
                hexes: h3.hexRing(updatedlocation.res11hex, 19),
            })
            res11safehexes.push({
                ring: 13,
                hexes: h3.hexRing(updatedlocation.res11hex, 20),
            })
            res11safehexes.push({
                ring: 14,
                hexes: h3.hexRing(updatedlocation.res11hex, 21),
            })
            res11safehexes.push({
                ring: 15,
                hexes: h3.hexRing(updatedlocation.res11hex, 22),
            })
            res11safehexes.push({
                ring: 16,
                hexes: h3.hexRing(updatedlocation.res11hex, 23),
            })
            res11safehexes.push({
                ring: 17,
                hexes: h3.hexRing(updatedlocation.res11hex, 24),
            })
            res11safehexes.push({
                ring: 18,
                hexes: h3.hexRing(updatedlocation.res11hex, 25),
            })
            res11safehexes.push({
                ring: 19,
                hexes: h3.hexRing(updatedlocation.res11hex, 26),
            })
            res11safehexes.push({
                ring: 20,
                hexes: h3.hexRing(updatedlocation.res11hex, 27),
            })

            const selectedResolutions = Object.keys(resolutions.value).filter(
                //@ts-expect-error kek
                (x: string) => resolutions.value[Number(x)]
            )

            console.log('render resolutions:', selectedResolutions)

            for (let sr = 0; sr < selectedResolutions.length; sr++) {
                const res = Number(selectedResolutions[sr])
                console.log('rendering res:', res)

                const geojson = getResolutionGeoJson(lat, lng, res)

                console.log('ready to render:', res)
                renderResolution(map, `hex-res-${res}`, geojson)
            }

            if (badRange.value) {
                const res11close = getResolutionGeoJson(lat, lng, 11, 7)
                renderResolution(
                    map,
                    'hex-res-11-close',
                    res11close,
                    'fill',
                    tooClosePaint
                )
            }

            if (goodRange.value) {
                if (
                    typeof res11safehexes !== 'undefined' &&
                    res11safehexes.length > 0
                ) {
                    let res11hexboundaries = []
                    let j
                    for (j = 0; j < res11safehexes.length; j++) {
                        let i
                        for (i = 0; i < res11safehexes[j].hexes.length; i++) {
                            //console.log("res11safehexes[j].hexes[i]: ", res11safehexes[j].hexes[i]);
                            let hexBoundary = h3.h3ToGeoBoundary(
                                res11safehexes[j].hexes[i]
                            )
                            hexBoundary.push(hexBoundary[0])

                            let arr = []
                            for (const i of hexBoundary) {
                                arr.push([i[1], i[0]])
                            }
                            //console.log(res11safehexes[j].ring);
                            res11hexboundaries.push({
                                ring: res11safehexes[j].ring,
                                boundaries: arr,
                            })
                        }
                    }

                    let i: number
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let features: any[] = []
                    for (i = 0; i < res11hexboundaries.length; i++) {
                        features.push({
                            type: 'Feature',
                            properties: {
                                title: 'Safe distance',
                                name: 'Safe distance',
                                longitude:
                                    res11hexboundaries[i].boundaries[0][0],
                                latitude:
                                    res11hexboundaries[i].boundaries[0][1],
                                ring: res11hexboundaries[i].ring,
                            },
                            geometry: {
                                type: 'Polygon',
                                coordinates: [res11hexboundaries[i].boundaries],
                            },
                        })
                    }
                    const geojson = {
                        type: 'FeatureCollection',
                        features: features,
                    }
                    renderResolution(
                        map,
                        'hex-res-11-safe',
                        geojson,
                        'fill',
                        safeRingPaint
                    )
                }
            }

            let hexBoundary = h3.h3ToGeoBoundary(res12hex)
            hexBoundary.push(hexBoundary[0])

            let arr = []
            for (const i of hexBoundary) {
                arr.push([i[1], i[0]])
            }

            const res12geojson = {
                type: 'Feature',
                properties: {
                    title: 'Searched Location',
                    name: 'Searched Location',
                },
                geometry: {
                    type: 'Polygon',
                    coordinates: [arr],
                },
            }

            renderResolution(
                map,
                'hex-res-12',
                //@ts-expect-error fuck types
                res12geojson,
                'fill',
                locationPaint
            )
        }

        onMounted(async () => {
            mapboxgl.accessToken =
                'pk.eyJ1IjoidGltbzk3MiIsImEiOiJja28xN2w4ejQwbXhoMnVwNG42MGRubmcxIn0.65mKns6UDI52ugS-qftUvw'
            map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11',
                zoom: config.zoom,
                center: [config.lng, config.lat],
                //pitch: 0,
                //bearing: -17.6,
                //antialias: true
            })

            map.on('click', (ev) => selectPoint(ev.lngLat.lng, ev.lngLat.lat))
            map.on('styledataloading', () => {
                loading.value = true
            })
            map.on('styledata', () => {
                loading.value = false
                rerender()
            })
            await new Promise((resolve) =>
                map.loaded() ? resolve(null) : map.on('load', resolve)
            )
            loading.value = false
        })

        watch(style, (val) => {
            map.setStyle(getMapStyle(val))
        })

        function rerender() {
            console.log('rerender')
            if (selectedPoint.value.length > 0) {
                selectPoint(selectedPoint.value[1], selectedPoint.value[0])
                return true
            }
            return false
        }

        watch(goodRange, (val) => {
            if (rerender() && !val) {
                clearResolution(map, 'hex-res-11-safe')
            }
        })

        watch(badRange, (val) => {
            if (rerender() && !val) {
                clearResolution(map, 'hex-res-11-close')
            }
        })

        for (const res in resolutions.value) {
            watch(
                //@ts-expect-error hurensohn
                () => resolutions.value[Number(res)],
                (val) => {
                    rerender()
                    if (!val && selectedPoint.value.length > 0)
                        clearResolution(map, `hex-res-${res}`)
                    console.log('changed res ', res, ':', val)
                }
            )
        }

        return {
            style,
            resolutions,
            expandMenu,
            showOptions,
            selectedPoint,
            locate,
            notifications,
            goodRange,
            badRange,
            loading,
        }
    },
})
</script>

<style lang="scss" scoped>
.map {
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 70px;
}
.home {
    display: flex;
    flex-direction: row;
    width: 100vw;
}
.menu {
    display: grid;
    grid-template-rows: 280px 280px auto 190px 170px;
    grid-template-columns: 70px;
    width: 70px;
    //background-color: rgb(30, 32, 38);
    background-color: rgb(18, 22, 28);
    transition: width 250ms ease;
    position: absolute;
    height: 100vh;
    z-index: 1;
    user-select: none;
    box-shadow: 3px 0px 13px 0px rgba(0, 0, 0, 0.92);

    .menu-btn {
        padding: 5px 10px;
        color: #fff;
        background: none;
        border: none;
        outline: none;
        border-radius: 5px;
        width: auto;
        font-size: 25px;
        transition: background 250ms ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2px 0;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
        i {
            padding: 5px;
        }
    }

    .menu-radio {
        opacity: 0;
        width: 83%;
        position: absolute;
        pointer-events: none;
    }

    .menu-radio-label {
        padding: 5px 10px;
        color: #fff;
        background: none;
        border: none;
        outline: none;
        border-radius: 5px;
        transition: all 250ms ease;
        //transition: background 250ms ease, border 250ms ease;
        cursor: pointer;
        border: 2px solid rgba(0, 0, 0, 0);
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .menu-radio:checked + .menu-radio-label,
    .menu-radio-label.active {
        border-color: rgb(240, 185, 11);
        //background-color: rgba(255, 255, 255, 0.1);
        background-color: rgba(106, 76, 12, 0.5);
    }

    .res-checkbox {
        padding: 5px 10px;
        color: #fff;
        background: none;
        border: none;
        outline: none;
        border-radius: 5px;
        width: auto;
        font-size: 18px;
        transition: background 250ms ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 31px;
        //width: 21px;
        border: 2px solid rgba(0, 0, 0, 0);
        margin: 2px 0;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
        &.active {
            border-color: rgb(46, 134, 193);
            background-color: rgba(8, 52, 82, 0.5);
        }
        .res-label {
            pointer-events: none;
            font-size: 40px;
            line-height: 0;
            position: relative;
            &::before {
                content: attr(data-res);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 12px;
            }
        }
    }

    .m-sec {
        //margin: 0px 12px 12px 12px;
        margin: 10px 12px 5px 12px;
        //display: flex;
        //flex-direction: column;
        display: grid;
        grid-template-columns: 48px;
        grid-auto-flow: row;
    }

    .label-cont {
        display: grid;
        margin: 10px 12px 12px 0px;
        grid-column-start: 2;
        grid-column-end: 2;
        //justify-content: space-evenly;
        justify-content: space-evenly;
        align-items: flex-start;
        label {
            --animate-duration: 500ms !important;
            --animate-delay: 0.5s !important;
            //height: 31px;
            //margin: 4px 0 4px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 5px 10px;
            padding-top: 12px;
            color: #fff;
            font-weight: 600;
            text-align: left;
            font-family: Inter, sans-serif;
            max-height: 30px;
        }
    }

    .map-styles {
        grid-row-start: 1;
        grid-row-end: 2;

        justify-content: center;

        border-bottom: 2px solid rgb(94, 102, 115);

        label {
            //width: 21px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2px 0;
            font-size: 18px;
            i {
                font-size: 25px;
                padding: 5px;
            }
        }
    }

    .style-grid {
        grid-template-rows: 50px 50px 50px 50px 50px;
    }

    .resolutions {
        grid-row-start: 2;
        grid-row-end: 2;
    }

    .res-grid {
        grid-template-rows: 50px 50px 50px 50px 50px;
    }

    .info {
        border-top: 2px solid rgb(94, 102, 115);
        border-bottom: 2px solid rgb(94, 102, 115);
        justify-content: center;
        padding-top: 10px;
        align-items: center;
        grid-row-start: 4;
        grid-row-end: 4;
        //height: 150px;
        a,
        button {
            height: 45px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2px 0;
            font-size: 18px;
            i {
                font-size: 25px;
                padding: 5px;
            }
        }
        a {
            color: #fff;
            border-radius: 5px;
            transition: background ease 250ms;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .info-grid {
        grid-template-rows: 50px 50px 50px;
        //grid-template-rows: 48px 48px 48px;
    }

    .options {
        //margin: 12px;
        grid-row-start: 5;
        grid-row-end: 5;
    }

    .option-grid {
        grid-template-rows: 50px 50px 50px;
    }

    &.expanded {
        width: 200px !important;
        grid-template-columns: 70px 130px;
        //grid-template-columns: 200px;
    }
}

.notifications {
    height: 100vh;
    min-width: 200px;
    max-width: 400px;
    z-index: 1;
    position: absolute;
    transition: all 250ms ease;
    transform: rotateZ(180deg);
    pointer-events: none;
    div {
        transform: rotate(180deg);
    }
}

.notify-enter-from {
    opacity: 0;
    top: 25px;
}

.notify-enter-to {
    opacity: 1;
    top: 0;
}

.notify-leave-active,
.notify-enter-active {
    transition: all 500ms ease;
    position: relative;
    z-index: 0;
}

.notify-leave-from {
    left: 0;
    opacity: 1;
}

.notify-leave-to {
    left: 25px;
    opacity: 0;
}
</style>
