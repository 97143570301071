/* eslint-disable @typescript-eslint/no-explicit-any */
import mapboxgl from 'mapbox-gl'
import * as h3 from 'h3-js'
import { polygonPaint } from './hexPaintings'
import { IGeoJson } from '@/types/GeoJson'

const kRingSize: { [key: number]: number } = {
    4: 1,
    5: 1,
    6: 1,
    7: 4,
    8: 12,
    9: 24,
    10: 48,
    11: 96,
}

export function getResolutionGeoJson(
    lat: number,
    lng: number,
    res: number,
    range?: number | any[]
): IGeoJson {
    const resHex = h3.geoToH3(lat, lng, res)
    if (resHex === 'undefined' || resHex.length < 1) {
        throw Error('could not get h3')
    }

    console.log('getting h3.kRing:', range || kRingSize[res])
    const kRingHexes = !(range instanceof Array)
        ? h3.kRing(resHex, typeof range === 'number' ? range : kRingSize[res])
        : range
    if (typeof kRingHexes === 'undefined' || kRingHexes.length < 1) {
        throw Error('could not get ring hexes')
    }

    const reshexboundaries = []
    for (let i = 0; i < kRingHexes.length; i++) {
        const hexBoundary = h3.h3ToGeoBoundary(kRingHexes[i])
        hexBoundary.push(hexBoundary[0])

        const mapped = hexBoundary.map((x) => [x[1], x[0]])

        reshexboundaries.push(mapped)
    }

    const features = []
    for (let i = 0; i < reshexboundaries.length; i++) {
        features.push({
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [reshexboundaries[i]],
            },
        })
    }
    const geojson = {
        type: 'FeatureCollection',
        features: features,
    }

    return geojson
}

export function renderResolution(
    map: mapboxgl.Map,
    sourceId: string,
    geodata: IGeoJson,
    type = 'line',
    paint: any = polygonPaint
): void {
    //const sourceId = "h3-hexes";
    const layerId = `${sourceId}-layer`
    let source = map.getSource(sourceId)

    if (!source) {
        map.addSource(sourceId, {
            type: 'geojson',
            //@ts-expect-error shitty types
            data: geodata,
        })
        map.addLayer({
            id: layerId,
            source: sourceId,
            //@ts-expect-error kek
            type,
            paint,
        })
        source = map.getSource(sourceId)
    }

    //@ts-expect-error wrong typings
    source.setData(geodata)
}

export function clearResolution(map: mapboxgl.Map, sourceId: string): void {
    //map.getSource(sourceId)
    map.removeLayer(`${sourceId}-layer`)
    map.removeSource(sourceId)
}
