import { createStore } from 'vuex'
import * as nanoid from 'nanoid'

export default createStore({
    state: {
        notifications: [
            /*{
title: "Message",
content: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
duration: 5000,
createdAt: + new Date(),
id: nanoid.nanoid(),
type: "error"
}*/
        ],
    },
    mutations: {
        addNotification(state, payload) {
            if (
                typeof payload.duration === 'number' &&
                typeof payload.title === 'string' &&
                typeof payload.content === 'string'
            )
                (state.notifications as unknown[]).push(
                    Object.assign(
                        {
                            duration: 5000,
                            createdAt: +new Date(),
                            id: nanoid.nanoid(),
                            type: 'default',
                        },
                        payload
                    )
                )
        },
        removeNotification(state, id) {
            ;(state.notifications as unknown[]) = (state.notifications as {
                id: string
            }[]).filter((x: { id: string }) => x.id !== id)
        },
    },
    actions: {},
    modules: {},
    getters: {
        notifications: (state) => state.notifications,
    },
})
