export default {
    satellite: 'Satellit',
    light: 'Hell',
    dark: 'Dunkel',
    streets: 'Straßen',
    outdoors: 'Landschaft',
    resolution: 'Auflösung {res}',
    togglegoodrange: 'Gute Entfernung',
    togglebadrange: 'Zu nah',
    readhip17: 'Mehr über die Hotspot-Verteilung',
    locate: 'Mein Standort',
    settings: 'Einstellungen',
    close: 'Schließen',
    notifications: {
        locate_succ: {
            title: 'Standort markiert!', // en: Marked location
            message:
                'Dein Standort wurde gefunden. Es ist wahrscheinlich, dass er nicht 100% korrekt ist.',
            // en:Found your geolocation, but its likely not 100% correct
        },
        locate_perm: {
            title: 'Standort nicht gefunden!', // en: Location failed
            message:
                'Du musst die Berechtigung erteilen, auf deinen Standort zuzugreifen.',
            // en: You have to allow geolocation in order to use this feature
        },
        locate_err: {
            title: 'Standort nicht gefunden!',
            message:
                'Dieser Browser unterstützt das Feature nicht! Wechsle zu einem anderen.',
            // en: This browser does not support geolocation
        },
    },
}
