
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import mapboxgl from 'mapbox-gl'
import * as h3 from 'h3-js'

import Notification from '@/components/Notification.vue'

import {
    clearResolution,
    getResolutionGeoJson,
    renderResolution,
} from '@/functions/hexagons'
import {
    locationPaint,
    safeRingPaint,
    tooClosePaint,
} from '@/functions/hexPaintings'
import LoadingOverlay from '@/components/LoadingOverlay.vue'

export default defineComponent({
    name: 'Home',
    components: { Notification, LoadingOverlay },
    setup() {
        const config = {
            lat: 52.2799,
            lng: 8.0472,
            zoom: 12,
        }

        const store = useStore()
        const i18n = useI18n()

        const notifications = computed(() => store.getters.notifications)

        const style = ref('streets')
        const resolutions = ref({
            //4: false,
            //5: false,
            6: false,
            7: true,
            8: true,
            9: false,
            10: false,
            //11: false,
            //12: false,
        })

        const goodRange = ref(true)
        const badRange = ref(true)
        const expandMenu = ref(false)
        const showOptions = ref(false)

        const loading = ref(true)
        /**
         * [lat, lng]
         */
        const selectedPoint = ref([0, 0])

        let map: mapboxgl.Map

        function getMapStyle(type: string): string {
            const baseUrl = 'mapbox://styles/mapbox/'

            switch (type) {
                case 'satellite':
                    return baseUrl + 'satellite-v9'
                case 'light':
                    return baseUrl + 'light-v10'
                case 'dark':
                    return baseUrl + 'dark-v10'
                case 'streets':
                    return baseUrl + 'streets-v11'
                case 'outdoors':
                    return baseUrl + 'outdoors-v11'
                default:
                    return baseUrl + 'streets-v11'
            }
        }

        function locate() {
            console.log('locate me')
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        selectPoint(pos.coords.longitude, pos.coords.latitude)

                        store.commit('addNotification', {
                            title: i18n.t('notifications.locate_succ.title'),
                            content: i18n.t(
                                'notifications.locate_succ.message'
                            ),
                            duration: 5000,
                        })
                    },
                    (err) => {
                        console.error(err)
                        store.commit('addNotification', {
                            title: i18n.t('notifications.locate_perm.title'),
                            content: i18n.t(
                                'notifications.locate_perm.message'
                            ),
                            duration: 5000,
                            type: 'error',
                        })
                    }
                )
            } else {
                store.commit('addNotification', {
                    title: i18n.t('notifications.locate_err.title'),
                    content: i18n.t('notifications.locate_err.message'),
                    duration: 5000,
                    type: 'error',
                })
            }
        }

        function selectPoint(lng: number, lat: number) {
            selectedPoint.value = [lat, lng]

            let newlatitude = 0
            let newlongitude = 0

            let res12hex = null
            let res11hex = null
            try {
                newlatitude = lat
                newlongitude = lng
                res12hex = h3.geoToH3(lat, lng, 12)
                res11hex = h3.geoToH3(lat, lng, 11)
            } catch {
                console.log('handleOnResult: Geolocate')
                newlatitude = lat
                newlongitude = lng
                res12hex = h3.geoToH3(lat, lng, 12)
                res11hex = h3.geoToH3(lat, lng, 11)
            }

            const updatedlocation = {
                latitude: newlatitude,
                longitude: newlongitude,
                res12hex: res12hex,
                res11hex: res11hex,
            }

            const res11safehexes = []
            res11safehexes.push({
                ring: 1,
                hexes: h3.hexRing(updatedlocation.res11hex, 8),
            })
            res11safehexes.push({
                ring: 2,
                hexes: h3.hexRing(updatedlocation.res11hex, 9),
            })
            res11safehexes.push({
                ring: 3,
                hexes: h3.hexRing(updatedlocation.res11hex, 10),
            })
            res11safehexes.push({
                ring: 4,
                hexes: h3.hexRing(updatedlocation.res11hex, 11),
            })
            res11safehexes.push({
                ring: 5,
                hexes: h3.hexRing(updatedlocation.res11hex, 12),
            })
            res11safehexes.push({
                ring: 6,
                hexes: h3.hexRing(updatedlocation.res11hex, 13),
            })
            res11safehexes.push({
                ring: 7,
                hexes: h3.hexRing(updatedlocation.res11hex, 14),
            })
            res11safehexes.push({
                ring: 8,
                hexes: h3.hexRing(updatedlocation.res11hex, 15),
            })
            res11safehexes.push({
                ring: 9,
                hexes: h3.hexRing(updatedlocation.res11hex, 16),
            })
            res11safehexes.push({
                ring: 10,
                hexes: h3.hexRing(updatedlocation.res11hex, 17),
            })
            res11safehexes.push({
                ring: 11,
                hexes: h3.hexRing(updatedlocation.res11hex, 18),
            })
            res11safehexes.push({
                ring: 12,
                hexes: h3.hexRing(updatedlocation.res11hex, 19),
            })
            res11safehexes.push({
                ring: 13,
                hexes: h3.hexRing(updatedlocation.res11hex, 20),
            })
            res11safehexes.push({
                ring: 14,
                hexes: h3.hexRing(updatedlocation.res11hex, 21),
            })
            res11safehexes.push({
                ring: 15,
                hexes: h3.hexRing(updatedlocation.res11hex, 22),
            })
            res11safehexes.push({
                ring: 16,
                hexes: h3.hexRing(updatedlocation.res11hex, 23),
            })
            res11safehexes.push({
                ring: 17,
                hexes: h3.hexRing(updatedlocation.res11hex, 24),
            })
            res11safehexes.push({
                ring: 18,
                hexes: h3.hexRing(updatedlocation.res11hex, 25),
            })
            res11safehexes.push({
                ring: 19,
                hexes: h3.hexRing(updatedlocation.res11hex, 26),
            })
            res11safehexes.push({
                ring: 20,
                hexes: h3.hexRing(updatedlocation.res11hex, 27),
            })

            const selectedResolutions = Object.keys(resolutions.value).filter(
                //@ts-expect-error kek
                (x: string) => resolutions.value[Number(x)]
            )

            console.log('render resolutions:', selectedResolutions)

            for (let sr = 0; sr < selectedResolutions.length; sr++) {
                const res = Number(selectedResolutions[sr])
                console.log('rendering res:', res)

                const geojson = getResolutionGeoJson(lat, lng, res)

                console.log('ready to render:', res)
                renderResolution(map, `hex-res-${res}`, geojson)
            }

            if (badRange.value) {
                const res11close = getResolutionGeoJson(lat, lng, 11, 7)
                renderResolution(
                    map,
                    'hex-res-11-close',
                    res11close,
                    'fill',
                    tooClosePaint
                )
            }

            if (goodRange.value) {
                if (
                    typeof res11safehexes !== 'undefined' &&
                    res11safehexes.length > 0
                ) {
                    let res11hexboundaries = []
                    let j
                    for (j = 0; j < res11safehexes.length; j++) {
                        let i
                        for (i = 0; i < res11safehexes[j].hexes.length; i++) {
                            //console.log("res11safehexes[j].hexes[i]: ", res11safehexes[j].hexes[i]);
                            let hexBoundary = h3.h3ToGeoBoundary(
                                res11safehexes[j].hexes[i]
                            )
                            hexBoundary.push(hexBoundary[0])

                            let arr = []
                            for (const i of hexBoundary) {
                                arr.push([i[1], i[0]])
                            }
                            //console.log(res11safehexes[j].ring);
                            res11hexboundaries.push({
                                ring: res11safehexes[j].ring,
                                boundaries: arr,
                            })
                        }
                    }

                    let i: number
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let features: any[] = []
                    for (i = 0; i < res11hexboundaries.length; i++) {
                        features.push({
                            type: 'Feature',
                            properties: {
                                title: 'Safe distance',
                                name: 'Safe distance',
                                longitude:
                                    res11hexboundaries[i].boundaries[0][0],
                                latitude:
                                    res11hexboundaries[i].boundaries[0][1],
                                ring: res11hexboundaries[i].ring,
                            },
                            geometry: {
                                type: 'Polygon',
                                coordinates: [res11hexboundaries[i].boundaries],
                            },
                        })
                    }
                    const geojson = {
                        type: 'FeatureCollection',
                        features: features,
                    }
                    renderResolution(
                        map,
                        'hex-res-11-safe',
                        geojson,
                        'fill',
                        safeRingPaint
                    )
                }
            }

            let hexBoundary = h3.h3ToGeoBoundary(res12hex)
            hexBoundary.push(hexBoundary[0])

            let arr = []
            for (const i of hexBoundary) {
                arr.push([i[1], i[0]])
            }

            const res12geojson = {
                type: 'Feature',
                properties: {
                    title: 'Searched Location',
                    name: 'Searched Location',
                },
                geometry: {
                    type: 'Polygon',
                    coordinates: [arr],
                },
            }

            renderResolution(
                map,
                'hex-res-12',
                //@ts-expect-error fuck types
                res12geojson,
                'fill',
                locationPaint
            )
        }

        onMounted(async () => {
            mapboxgl.accessToken =
                'pk.eyJ1IjoidGltbzk3MiIsImEiOiJja28xN2w4ejQwbXhoMnVwNG42MGRubmcxIn0.65mKns6UDI52ugS-qftUvw'
            map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11',
                zoom: config.zoom,
                center: [config.lng, config.lat],
                //pitch: 0,
                //bearing: -17.6,
                //antialias: true
            })

            map.on('click', (ev) => selectPoint(ev.lngLat.lng, ev.lngLat.lat))
            map.on('styledataloading', () => {
                loading.value = true
            })
            map.on('styledata', () => {
                loading.value = false
                rerender()
            })
            await new Promise((resolve) =>
                map.loaded() ? resolve(null) : map.on('load', resolve)
            )
            loading.value = false
        })

        watch(style, (val) => {
            map.setStyle(getMapStyle(val))
        })

        function rerender() {
            console.log('rerender')
            if (selectedPoint.value.length > 0) {
                selectPoint(selectedPoint.value[1], selectedPoint.value[0])
                return true
            }
            return false
        }

        watch(goodRange, (val) => {
            if (rerender() && !val) {
                clearResolution(map, 'hex-res-11-safe')
            }
        })

        watch(badRange, (val) => {
            if (rerender() && !val) {
                clearResolution(map, 'hex-res-11-close')
            }
        })

        for (const res in resolutions.value) {
            watch(
                //@ts-expect-error hurensohn
                () => resolutions.value[Number(res)],
                (val) => {
                    rerender()
                    if (!val && selectedPoint.value.length > 0)
                        clearResolution(map, `hex-res-${res}`)
                    console.log('changed res ', res, ':', val)
                }
            )
        }

        return {
            style,
            resolutions,
            expandMenu,
            showOptions,
            selectedPoint,
            locate,
            notifications,
            goodRange,
            badRange,
            loading,
        }
    },
})
